<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #searchSlot>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="font-size: 14px; margin-right: 10px">时间查询：</div>
          <v-date-picker
            v-model="datetimerange"
            type="datetimerange"
            clearable
            @change="changeDate"
          ></v-date-picker>
        </div>
        <v-input
          label="订餐人姓名"
          v-model="searchParam.name"
          placeholder="请输入订餐人姓名"
        ></v-input>
        <v-input
          label="订餐人手机号"
          v-model="searchParam.mobile"
          placeholder="请输入订餐人手机号"
        ></v-input>
        <v-select
          v-model="searchParam.orderType"
          clearable
          :options="scheduledTime"
          label="预定时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.orderStatus == 1"
          text="取消订单"
          type="text"
          @click="remove(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { allOrderListUrl, cancelOrderUrl } from "./api.js";
import { scheduledTime } from "./map.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "canteenOrder",
  data() {
    return {
      scheduledTime,
      tableUrl: allOrderListUrl,
      searchParam: {
        name: null,
        mobile: null,
        orderType: null,
        startTime: null,
        endTime: null,
      },
      datetimerange: [],
      headers: [
        {
          prop: "intime",
          label: "日期",
        },
        {
          prop: "receiverName",
          label: "订餐人姓名",
        },
        {
          prop: "receiverPhone",
          label: "手机号",
        },
        {
          prop: "houseName",
          label: "房号",
        },
        {
          prop: "mealInfoList",
          label: "套餐名称",
          formatter: (row, prop) => {
            let list = [];
            row.mealInfoList.forEach((item) => {
              list.push(item.mealTitle);
            });
            return list.join(",");
          },
        },
      ],
    };
  },
  methods: {
    remove(item) {
      MessageBox.confirm("是否取消改条订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            userId: this.$store.state.app.userInfo.userId,
            orderId: item.id,
          };
          this.$axios
            .post(`${cancelOrderUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    changeDate() {
      if (this.datetimerange && this.datetimerange.length == 2) {
        this.searchParam.startTime = this.datetimerange[0].format("yyyy-MM-dd");
        this.searchParam.endTime = this.datetimerange[1].format("yyyy-MM-dd");
      } else {
        this.searchParam.startTime = null;
        this.searchParam.endTime = null;
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
